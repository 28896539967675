<style lang="css">
.center-md {
  font-size: 30px;
  padding: 0.4rem;
}

.order-info-md {
  text-align: left;
}

.order-info-md > div {
  line-height: 0.6rem;
}

.order-code-md > .time {
  float: right;
}

.bankinfo-center {
  background-color: #FFFFFF;
  margin: 0.3rem 0;
  border-radius: 8px;
  border: 0px;
  -moz-box-shadow: 2px 1px 4px #d2d2d2;
  -webkit-box-shadow: 2px 1px 4px #d2d2d2;
  box-shadow: 2px 1px 4px #d2d2d2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444')";
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444');
}

.bankinfo-item {
  display: flex;
  height: 1.2rem;
  align-items: center;
  padding: 0 0.25rem;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  text-align: left;
}


.copy-md {
  font-size: 0.20rem;
  color: #ffffff;
  margin-left: 0.18rem;
  padding: 0.1rem 0.25rem;
  text-align: center;
  font-weight: 500;
  background-color: #2a3dee;
  border-radius: 18px;
  text-decoration: none;
}

.orderCode-md {
  position: relative;
  color: #000000;
  font-weight: 800;
}

.amount-md {
  color: #7b8dcc;
}

.paymentinfo-md {
  margin-bottom: 11px;
}

.payment-info-title {
  text-align: left;
}

.input-orderCode {
  position: relative;
  margin: 0.4rem 0;
}

.input-orderCode > label {
  position: absolute;
  top: 0.4rem;
  left: 0.25rem;
}

.inpu-orderCode {
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: right;
  padding: 0.4rem 0.2rem 0.4rem 3rem;
  font-size: 0.45rem;
  border: 0px;
  outline: none;
}

.sumbit-but {
  display: inline-block;
  width: 100%;
  font-size: 0.45rem;
  background-color: #2a3dee;
  padding: 0.25rem 0px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  border-radius: 8px;
}

.el-icon-upload2 {
  background-image: url("../assets/images/相机.png");
}

.select-img > div {
  display: block;
  width: 2.8rem;
  border: 0.013333rem solid #cac8c8;
  height: 2.8rem;
  background-image: url("../assets/images/相机.png");
  background-repeat: no-repeat;
  background-size: 0.8rem;
  background-position: 50%;
  outline: none;
}

.select-img > div > img {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.swal2-content {
  font-size: 0.425rem !important;
}

.swal2-actions {
  flex-wrap: nowrap !important;
  margin: 0.45em auto 0;
  overflow: hidden;
}

.swal2-actions > button {
  width: 50%;
  font-size: 0.525rem !important;
  text-align: center;
  /*border-radius: 0 !important;*/
  border-color: #ffffff;
}

.swal2-content {
  padding: 0.55rem 0 0 0;
}

.swal2-actions > button:nth-child(1) {
}

.swal2-actions > button:nth-child(2) {
  color: #a7a7a7 !important;
}

.swal2-styled {
  padding: 0.625em 0em;
  margin: 0;
  background-color: white !important;
}

.swal2-popup {
  padding: 0;
  overflow: hidden;
}

.swal2-title {
  font-size: 0.5em !important;
}

.swal2-icon.swal2-warning {
  width: 1rem;
  height: 1rem;
  font-size: 0.2rem !important;
  font-weight: 800 !important;
}

#swal2-content {
  text-align: left;
  padding: 0 0.5rem;
}

.okbut {
  border: 0;
  width: 100% !important;
  margin: 0 0.45rem;
  border-radius: 1rem !important;
  color: #ffffff !important;
  margin-bottom: 0.3rem;
  background: -webkit-gradient(linear, 80 -82, -48 bottom, from(#f27005), to(#ededed)) !important; /*Safari */
  background: -moz-linear-gradient(top, #f27005, #ededed) !important; /*Firefox*/
}

.layui-layer-loading .layui-layer-content {
  width: 1.4rem !important;
  height: 0.6rem !important;
}

.layui-layer-hui .layui-layer-content {
  padding: 0.46rem 0.433333rem !important;
  text-align: center;
}

.layui-layer-dialog .layui-layer-content {
  font-size: 0.386667rem !important;
}
.lable-var-md{
  display: flex;
  align-items: center;
}
.national_flag{
  width: 1.5rem;
  height: 1rem;
}
</style>

<template>
  <div class="center">
    <div class="center-md">
      <!-- 订单号 订单时间 -->
      <header class="order-info-md">
        <div class="order-code-md order-item">
          <span
              class="order-id">Order Id: {{
              queryObject.payOrderId == undefined ? 'unknown' : queryObject.payOrderId
            }}</span>
          <span class="time">12:09</span>
        </div>
        <div class="order-time order-item">
          <span
              class="pay-order-time">Time: {{
              queryObject.createdAt == undefined ? 'unknown' : queryObject.createdAt
            }}</span>
        </div>
      </header>
      <div class="bankinfo-center">
        <div class="bankinfo-item">
          <label class="label-pl" style="color: #a7a7a7;">Name</label>
          <div class="lable-var-md">
            <span>{{ queryObject.name == undefined ? 'unknown' : queryObject.name }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl">Bank card</label>
          <div class="lable-var-md">
            <span class="orderCode-md">{{ queryObject.bankCardNo == undefined ? 'unknown' : queryObject.bankCardNo }}</span>
            <a href="#" class="copy-md" @click="copyFn(queryObject.bankCardNo)">Copy</a>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl">currency</label>
          <div class="lable-var-md">
            <span class="orderCode-md">{{ queryObject.currency == undefined ? 'unknown' : queryObject.currency }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl">national flag</label>
          <div class="lable-var-md">
              <img class="national_flag" :src="queryObject.logo">
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl" style="color: #a7a7a7;">Amount</label>
          <div class="lable-var-md">
            <span class="amount-md">{{ queryObject.amount == undefined ? 'unknown' : (queryObject.amount /100).toFixed(2) }}</span>
            <a href="#" class="copy-md" @click="copyFn(queryObject.amount)">Copy</a>
          </div>
        </div>
      </div>
      <div class="paymentinfo-md">
        <div class="payment-info-title">Payment information</div>
        <div class="payment-info-input-md">
          <div class="input-orderCode">
            <label>UTR/Ref No</label>
            <input class="inpu-orderCode" v-model="inputOrderId" type="text" placeholder="Ref No"/>
          </div>
          <div class="select-img">
            <div @click="goFile" size="small" type="primary">
              <img class="orderinfoimg" v-show="orderinfoimg" :src="orderinfoimg"/>
            </div>
            <input style="display: none"
                   id="file" ref="files"
                   name="orderInfoImg"
                   type="file"
                   @change="uploadImg(this)"
                   accept=".jpg,.png"/>
          </div>
        </div>
      </div>
      <div class="paymentinfo-md">
        <div class="payment-info-title two-pl">Please Submit UTR Or Upload The ScreenShot</div>
        <div class="payment-info-input-md">
          <div class="input-orderCode">
            <a class="sumbit-but" @click="submitbut" title="Submit Ref Number">Submit Ref Number</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
import {reactive, toRefs} from 'vue'
import {cardQueryACardOrder, pushACardOrder} from '@/api/api'
import $ from 'jquery'
import layer from "layui-layer";
import config from "@/config";


export default {
  data() {
    return {
      orderinfoimg: '',
      queryObject: {},
      file: null,
      inputOrderId: null,
      orderId: null
    }
  },
  components: {},
  mounted() {
    const _this = this
    // 获取到订单号
    _this.orderId = this.$route.params.id
    // 请求后台获取 获取个卡订单
    cardQueryACardOrder(_this.orderId).then(res => {
      _this.queryObject = res
      console.log(res)
    }, function (err) {
      _this.$alert('', err.msg, "error").then(() => {
        _this.$router.push({name: config.errorPageRouteName, params: {errInfo: '这个订单有问题！请不要试图修改订单号！'}})
      });
      $(".swal2-confirm").addClass("okbut")
    })
    // 弹出层
    this.$alert('1', "Attention", "warning");
    $(".swal2-content > div").html("Step 1:Complete payment<br/>Step 2: Go bach here and submit Ref/UTR No,(12 digits)<br/>Step 3: About 10 minutes,your money will arrive in your accourt Reminder;UPL address changes frequently,and payment must be made to the UPL address on the current page each time.Otherwise your money will be lost!!!")
    $(".swal2-confirm").addClass("okbut")
  },
  methods: {
    submitbut() {
      const _this = this
      if (_this.queryObject.payOrderId == undefined) {
        _this.$alert('', "当前页面参数有误！不能进行提交", "error");
        $(".swal2-confirm").addClass("okbut")
        return
      }
      if (_this.inputOrderId == null) {
        layer.msg('The payment order number cannot be empty！', {time: 1500});
        return
      }
      console.log(_this.file)
      if (_this.file == null) {
        layer.msg('Please upload order transaction screenshot', {time: 1500});
        return
      }
      _this.$confirm("Please cack confirm!").then(() => {
        const loading = layer.load(3, {//请求未成功时出现loading页 ,3代表一中效果
          shade: [0.1, '#fff'] //0.1透明度的白色背景
        });
        const formData = new FormData()
        formData.append("file", _this.file)
        formData.append("inpuOrderId", _this.inputOrderId)
        formData.append("payOrderId", _this.orderId)
        for (var key of formData.entries()) {
          console.log(key[1])
        }
        // 客户已明确要提交
        pushACardOrder(formData).then(() => {
          this.$router.push({path: '/ACardPayComplete/' + _this.orderId})
          layer.close(loading);
        }).catch(() => {
          layer.msg('上传失败');
          layer.close(loading);
        })
      }, function () {
        // 取消了
      });
      $(".swal2-confirm").removeAttr("style")
      $(".swal2-content > div:first-child").attr("style", "text-align:center");
      $(".swal2-confirm").attr("style", "color:#7b8dcc !important");
    },
    copyFn(val) {
      // createElement() 方法通过指定名称创建一个元素
      var copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$alert("", "复制成功", "success");
          $(".swal2-confirm").addClass("okbut")
          console.log("复制成功");
        }
      } catch {
        console.log("复制失败，请检查浏览器兼容");
      }
    },
    goFile() {
      this.$refs.files.dispatchEvent(new MouseEvent('click'));
    },
    uploadImg(es) {
      const _this = this;
      var e = window.event || es // change事件获取到的数据
      if (e.target.files[0].size > 2 * 1024 * 1024) { // 限制上传图片文件大小
        console.log('传单个封面大小不能超过 2M')
      } else {
        // 获取图片地址
        var file = e.target.files[0]
        _this.file = file
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (result) {
          _this.orderinfoimg = result.target.result // 图片地址 Base64 格式的 可预览
        }
      }
    }
  },
  setup() {
    // eslint-disable-next-line no-undef
    const state = reactive({
      loading: false,
      pageInfo: {
        page: 1,
        rows: 10,
        total: 0,
      }
    })
    // 最后return 出去
    return {
      ...toRefs(state),
    }
  }
}
</script>

